import * as React from 'react';
import imageDbIcon from './../../images/ImageDB/ImageDbIco.png';
import imageDBFilm from './../../images/ImageDB/ImageDBMainWindowFilm.png';
import imageDBMusik from './../../images/ImageDB/ImageDBMainWindowMusik.png';
import imageDBMusikDialog from './../../images/ImageDB/ImageDBMusikDialog.png';
import './ImageDB.css';
import Helmet from 'react-helmet';
import { appInsights } from 'src/AppInside';

class ImageDB extends React.Component<any, any>  {

  public UNSAFE_componentWillMount() {
    appInsights.trackPageView({
      name: 'Petrisoft ImageDB page', uri: "https://www.petrisoft.dk/imagedb",
      isLoggedIn: true, properties: { "action": "componentDidMount" }
    });
  }

  public render() {
    return (
      <div>

         <Helmet>
          <title>ImageDB: Registrering af film, musik, bøger, kunst mm</title>
          <meta name="description" content="ImageDB: Registrering af Film, musik, bøger mm" />
          <meta name="Keywords"
            content="ImageDB, registrering, film, musik, bøger, kunst, film database, musik database, bog database, kunst database" />
          <meta name="author" content="Kurt Petri Petersen, PetriSoft" />
        </Helmet>
        
        <h1>ImageDB</h1>

        <h4>
          <img className="img-app-icon" src={imageDbIcon} alt="CoinDB" />&nbsp;
          ImageDB: Registrer din Film-, Musik-, Bog-, Kunstsamling
        </h4>

        <p>
          <br />
          <b>ImageDB</b> er et Windows program, hvor du kan registrere og vedligeholde dine forskellige samlinger:
          musik, film, bøger og kunst osv.
        </p>
        <p>
          Programmet har  standart opsætninger for forskellige typer af databaser:
        </p>

        <ul>
          <li>
            <b>Musik samling: </b>
            Cd'er, Musik Dvd’er, Lp’er, podcast, youtube video mm. Gem informationer om din musiksamling som
            kunstner, titel, år, genre, beskrivelse, link til kunstnerens webside, lydklip, anmeldelser, serie nummer,
            bemærkninger, kvalitet, bedømmelse (f.eks. 1-5).
          </li>
          <li>
            <b>Film samling: </b>
            DVD, Blue-ray, Video. Gem informationer om din filmsamling som beskrivelse, genre, titel, år, instruktør,
            bedømmelse, billeder, link til f.eks. filmklip på nettet/eller på PC, &quot;om filmen&quot;,
            Serie nummer, Bemærkninger, Kvalitet, Web Link 2.
          </li>
          <li>
            <b>Bog samling: </b>
            Indsæt din egen beskrivelse i diverse felter: Genre, Forfatter, Titel, Udg. År, Pris/vurdering,
            Sider, Billeder,Ansk. dato, Kategori, Noter (ISBN), Forlag/Udgiver, Bedømmelse, Resume, Web link, anmeldelse
          </li>
          <li>
            <b>Kunst eller maleri samling: </b>
            Billeder af kunstgenstand, vurdering, gruppe, stilart, beskrivelse, web-link osv.
          </li>
          <li><b>Adresse register: </b>
            Privat-, Kunde-, leverandør- register. Indtast f.eks. adresse, telefon, fax, e-mail, web, beskrivelse mm
          </li>
        </ul>

        <p>
          Det er let at oprette og vedligeholde forskellige typer af registre/samlinger via en særdeles brugervenlig og simpel grafisk
          brugerflade indeholdende oversigtslister/rapporter i html, XML og ASCII tekst. Herunder ses dialogen du kan indtaste
          information om en CD i din samling:
        </p>

        <figure>
          <img className="img-imagedb-1" src={imageDBMusikDialog} alt="CoinDB med dialog til regdigering af poster" />
          <figcaption>PetriSoft's ImageDB - dialog til vedligeholdelse af musiksamling</figcaption>
        </figure>

        <br />

        <p>
          Man kan for en post gemme billeder, beskrivelse, link til web (f.eks. youtube video mm),
          link til lokale waw eller mp3 filer og meget mere - så man kan afspille lyd fil med nummer fra album.
          Yderligere er det muligt at oprette
          og vedligeholde flere forskellige registre. F.eks kan man gemme sine film i et register,
          sin musiksamling i et andet og sine malerier i et tredje.
        </p>

        <h3>
          ImageDB findes i 3 forskellige versioner:
        </h3>

        <p>
          <b>ImageDB (Free):</b> er en gratis version af programmet men du kan gemme op til 25
          poster i en database. Prøv denne så du kan evaluere programmet inden du køber en licens :-).
        </p>
        
        <p>
          <b>ImageDB Std:</b> Ingen begrænsning i antallet poster, der kan gemmes og opret op til 15 forskellige
          grupper eller kategorier. Email support inkluderet.
        </p>
        <p>
          <b>ImageDB Pro:</b> er for brugeren, der ikke ønsker nogen form for begrænsning.
          Denne version er ideel til alle med et
          stort behov at kunne definere mange forskellige grupperinger/typer . Her kan der
          f.eks. oprettes et ubegrænset antal film eller musik genrer. Desuden indeholder denne version
          et XML export format.
        </p>
        <p>
          Husk at kigge i menu’en <b>Hjælp</b> samt anvende <b>Shift</b> + <b><i>F1</i></b> 
          (= Hjælp, Hvad er det ?) på de funktioner, knapper og lister du er i tvivl om.
        </p>
        <p>
          Tip: Alle felter kan frit omdøbes efter behov.
        </p>

        <h3>Diverse eksempler</h3>

        <h4>Film</h4>

        <figure>
          <img className="img-imagedb-2" src={imageDBFilm} 
          alt="ImageDB - film samling" />
          <figcaption>ImageDB - eksempel på film samling.</figcaption>
        </figure>

        <h4>Musik</h4>

        <figure>
          <img className="img-imagedb-3" src={imageDBMusik} 
          alt="ImageDB - musik samling" />
          <figcaption>ImageDB - eksempel på musik samling.</figcaption>
        </figure>

      </div>
    );
  }
}

export default ImageDB;