import * as React from 'react';
import inventoryDbIcon from './../../images/InventoryDB/InventoryDBIco.png';
import nptScan from 'src/images/InventoryDB/NPTscan_stor.jpg';
import zebra from 'src/images/InventoryDB/Zebra2844-z.gif';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { appInsights } from 'src/AppInside';

class InventoryDB extends React.Component<any, any>  {

  public UNSAFE_componentWillMount() {
    appInsights.trackPageView({
      name: 'Petrisoft InventoryDB page', uri: "https://www.petrisoft.dk/inventorydb",
      isLoggedIn: true, properties: { "action": "componentDidMount" }
    });
  }

  public render() {
    return (
      <div>

        <Helmet>
          <title>InventoryDB: Registrering af indbo og inventar med stregkoder</title>
          <meta name="description" content="InventoryDB: Registrering af indbo og inventar med stregkoder" />
          <meta name="Keywords"
            content="InventoryDB, registrering, indbo, inventar, stregkoder, asset management, stregkode printer, stregkode skanner" />
          <meta name="author" content="Kurt Petri Petersen, PetriSoft" />
        </Helmet>

        <h1>InventoryDB</h1>

        <h4>
          <img className="img-app-icon" src={inventoryDbIcon} alt="Inventar registrering" />&nbsp;
          Registrering af inventar
        </h4>

        <p>
          <br />
          <b>InventoryDB</b> anvendes til registrering af indbo og inventar med stregkoder.
        </p>

        <p>
          InventoryDB er et relativ simpelt 'asset management system' til Windows, der kan anvendes til
          registrering og eventuel flytning af inventar, computer udstyr, software
          programmer, værktøj mm ved brug af stregkoder.
        </p>

        <p>
          Det er let at lave et komplet register over alt sit inventar og dermed
          give mangfoldige muligheder for søgninger, diverse rapporter og lister,
          udskrive stregkoder på en stregkode printer samt tilslutte en skanner til læsning af stregkoder.
        </p>

        <p>
          Det er primært udviklet til diverse inventar registrering og efterfølgende "sporing" af inventar.
          Dvs. bestemme i hvilket lokale/lokation er inventaret placeret.
        </p>

        {/* <p>
          Programmet kan desuden også håndtere registrering og sporing af f.eks. <b>værktøjsudleje</b> samt 
          diverse <b>materialeforbrug </b> for projekter eller personer.
        </p> */}

        <p>
          PetriSoft har udviklet inventar registreringsprogrammet i et tæt
          samarbejde med det danske firma "Norris Print-Tech A/S, der er ekspert inden for
          etiketter og stregkoder og pt.        er den foretrukne HW/etikette leverandør.
        </p>

        <p>
          Det muligt at oprette og vedligeholde flere forskellige registre.
          F.eks kan man bruge programmet til at gemme firmaets inventar i et
          register og firmaets SW pakker i et andet osv
        </p>

        <p>
          Programmet har som nævnt mange forskellige anvendelses muligheder.
          Det kan bl.a. anvendes til registreringsopgaver til
          inventar, inventar flytning, udleje af værktøj, materiale forbrug på person/projekt mm
        </p>

        <p>
          Alt Inventar kan påklistres en stregkode etiket, der unikt
          identificerer det. Flyttes et inventar f.eks til andre lokaler, kan det
          let spores hvor det er, idet man via en håndterminal med integreret stregkodescanner
          f.eks. Symbol MC1000 fra
          <a rel="noreferrer" href="http://www.npt.dk/" target="_blank"> Norris Print-Tech A/S</a>:
        </p>

        <figure>
          <img className="img-inventorydb-1" src={nptScan} alt="CoinDB" />
          <img className="img-inventorydb-1" src={zebra} alt="CoinDB" />
          <figcaption>Symbol MC1000 (nptscan) til indskanning af stregkoder samt zebra stregkode printer</figcaption>
        </figure>

        <p>
          &nbsp;
        </p>

        <p>
          Skanneren kan indlæse <b>lokaleid, inventarid</b> for alt inventar og derved
          opdatere sin inventar database i InventoryDB.
        </p>

        <p>
          Stregkoder for både lokaler samt inventar kan udskrives direkte til
          en stregkode printer - f.eks Zebra TLP2844-Z.
        </p>

        {/* <p>
          Vis produktblad om <a href="InventoryDBDatablad_dk.pdf">InventoryDB</a> (pdf dokument)
        </p> */}

        <p />

        <h3>Programmet findes i 2 forskellige versioner:</h3>

        <p>
          <b>InventoryDB Free </b>er gratis demo software. De fleste funktioner kan afprøves og man kan gemme op til 25
          poster.
        </p>

        <p>
          En demo version af InventoryDB kan hentes her:&nbsp;
          <Link to="/download">Download</Link>
        </p>

        <p>
          <b>InventoryDB Pro </b>er for den professionelle bruger - dvs firmaer som ønsker at kunne spore deres
          assets via stregkoder. Her kan der oprettes et ubegrænset antal poster, grupper,
          lokaler samt leverandører mm. I denne version er XML output supporteret, stregkode ssanner interface samt stregkode printer interface.
          Flere brugere kan samtidig bruge programmet. En bruger, kan opdatere databasen, mens andre brugere er i
          "read only" tilstand.
        </p>

        <p>
          Læs eventuelt om flere detaljer i 'Kom godt i gang med InventoryDB' i  menuen: 'Hjælp' i
          programmet.
        </p>


        <h3>Tyveri sikring ?</h3>
        InventoryDB programmet kan også med fordel anvendes i forbindelse med
        vurdering af forsikringssum. I tilfælde af tyveri/brand kan det
        give en god dokumentation over evt. mistede genstande. Man
        kan gemme anskaffelseværdi, vurdering, div. leverandør informationer,
        adresser, billede, kvittering, manual/brochure - kort sagt alt der
        beskriver inventar eller produkter.

        <p>
          &nbsp;
        </p>

        <p>
          Tip: Alle felter kan omdøbes efter behov.
        </p>


      </div>
    );
  }
}

export default InventoryDB;