import * as React from 'react';
import { Link } from 'react-router-dom';
import { appInsights } from 'src/AppInside';
import './About.css';

class About extends React.Component<any, any>  {

  public UNSAFE_componentWillMount() {
    appInsights.trackPageView({
      name: 'Petrisoft About page', uri: "https://www.petrisoft.dk/about",
      isLoggedIn: true, properties: { "action": "componentDidMount" }
    });
  }

  public render() {
    return (
      <div>
        <h2>Om PetriSoft</h2>

           PetriSoft udvikler og sælger en lang række af programmer til forskellige registrerings opgaver som:

        <div className="liste">
          <ul>
            <li>indbo og inventar</li>
            <li>inventar registrering m stregkoder</li>
            <li>film-, musik- og bog samlinger</li>
            <li>møntsamlinger</li>
            <li>frimærkesamlinger</li>
            <li>seddelsamlinger</li>
            <li>løb- og træningsresultater (f.eks. løb, cykling, mtb, svømning...)</li>
          </ul>
        </div>

        <p>
          Det vores mål at udvikle, vedligeholde og supportere brugervenlige programmer af høj kvalitet til både private,
          firmaer samt offentlige institutioner mm.
        </p>
        <p>
          Der kræves ikke nogen særlige forkundskaber for at kunne anvende
          programmerne, idet brugervenlighed samt kundetilpasning, altid har top prioritet.
        </p>
        <p>
          Programmerne kan frit <a href="..\Download\Download.html">downloades</a> og afprøves - helt uden tidsbegrænsning kun begrænset i antal poster der kan gemmes.
        </p>
        <p>
          PetriSoft udvikler programmerne i C# og C++ samt JavaScript, ReactJs og Typescript,.
          Vi anvender både MicroSoft .Net, Web samt QT platform/framework.
              </p>
        <p>
          PetriSoft sælger primært programmerne til Danmark, Norge samt alle engelsk
          talende lande. Dansk, Norsk samt engelsk sprog kan vælges i de fleste af programmerne.
              </p>
        <p>
          Yderligere leverer PetriSoft også gerne diverse relaterede software ydelser (herunder foredrag og undervisning)
          og løsninger på konsulentbasis i tæt dialog med kunderne, til aftalt tid, pris og funktionalitet :-)
        </p>

        <section>
          <h2>Artikler om programmer</h2>
          <Link to="/articles">Vis Artikler</Link>
        </section>

        <hr />

        <section>
          Læs evt. mere om PetriSoft på facebook:<br /><br />

          <a href="https://www.facebook.com/PetriSoft">PetriSoft på facebook</a>
        </section>

      </div>
    );
  }
}

export default About; 