import * as React from 'react';
import { Navbar, NavbarBrand } from 'react-bootstrap';
import Container from 'reactstrap/lib/Container';

import './Footer.css';

class Footer extends React.Component<any, any>  {

  public render() {
    return (
      <div className="Footer" >
        <Navbar className="navbar navbar-light bg-light" >
          <Container>
            <NavbarBrand>&copy; Copyright 2024 - PetriSoft - email: petrisoft@gmail.com </NavbarBrand>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default Footer;
