import * as React from 'react';
import { appInsights } from 'src/AppInside';
import './Order.css';
import * as emailjs from 'emailjs-com';
import { useState, useEffect } from 'react';
import { Button, TextField, Stack, Select, SelectChangeEvent, MenuItem, InputLabel, FormControl, FormHelperText, InputAdornment } from '@mui/material';
import { Comment, Email, Home, LocationCity, Phone } from '@mui/icons-material';

const Order: React.FC = () => {

  const [userName, setUserName] = useState('');
  const [address, setAddress] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [subjectMail, setSubjectMail] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [disableSend, setDisableSend] = useState<boolean>(true);

  useEffect(() => {
    appInsights.trackPageView({
      name: 'Petrisoft Order page', uri: "https://www.petrisoft.dk/order",
      isLoggedIn: true, properties: { "action": "componentDidMount" }
    });

  }, [])

  function handleSubmit(event: any) {

    event.preventDefault()

    const templateParams = {
      subject: subjectMail,
      user_name: userName,
      user_address: address,
      user_zip: zip,
      user_city: city,
      user_email: email,
      user_message: message,
      user_phone: phone
    };

    console.log('emailjs.sendForm called', templateParams)

    // emailjs.send('default_service', 'petrisoft_sendmail', templateParams, 'user_IumAuMjfFFb5Hb7uoisFy')
    emailjs.send('default_service', 'petrisoft_sendmail', templateParams, 'user_IumAuMjfFFb5Hb7uoisFy')
      // tslint:disable-next-line:only-arrow-functions
      .then(function (response: { status: any; text: any; }) {
        alert("email sendt til PetriSoft - status: ok")
        console.log('SUCCESS!', response.status, response.text);
        // tslint:disable-next-line:only-arrow-functions
      }, function (err: any) {
        console.log('FAILED...', err);
        alert("email error: " + err.status + ", " + err.text);
      });

    appInsights.trackEvent({ name: 'Order to PetriSoft', properties: { templateParams } });
  }

  useEffect(() => {
    setEmailError(false);

    if (email !== "") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(email)) {
        setEmailError(true);
        setDisableSend(true);
        return;
      }
    }

    if (subjectMail !== "" && address !== "" && zip !== "" && city && userName !== "") {
      setDisableSend(false);
    }
    else {
      setEmailError(true);
      setDisableSend(true);
    }
  }, [subjectMail, userName, address, zip, city, email])


  const handleSelectProgramChange = (event: SelectChangeEvent) => {
    setSubjectMail(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <div>
      <header>
        <h2>Bestil et program</h2>
      </header>
      <section>

        <form id="contact-form">
          <Stack spacing={2} >

            <input type="hidden" name="recipient" value="kurt@petrisoft.dk" />
            <br></br>
            <h4>Udfyld formularen for at bestille en licens eller skrive en besked til PetriSoft.</h4>
            <br></br>

            {/* <FormControl variant="standard" fullWidth> */}
            <FormControl
              fullWidth
              size="small"
              required
            >

              <InputLabel id="program-select-standard-label">Program</InputLabel>

              <Select className="SelectProgram"
                labelId="program-select-standard-label"
                id="program-select-standard"
                label="Program"
                value={subjectMail}
                onChange={handleSelectProgramChange}
              >

                <MenuItem value={"Indbo Std (249kr/35€)"}>Indbo Std - www download (249dkk/35€)</MenuItem>
                <MenuItem value={"Indbo Std - CDROM (299kr/42€)"}>Indbo Std - CDROM (299dkk/42€)</MenuItem>
                <MenuItem value={"Indbo (virksomhed, institution...) (999kr/42€)"}>Indbo (virksomhed, institution...) (999dkk/70€)</MenuItem>
                <MenuItem value={"CoinDB Std (249kr/35€)"}>CoinDB Std - www download (249dkk/35€)</MenuItem>
                <MenuItem value={"CoinDB Std - CDROM (299kr/42€)"}>CoinDB Std - CDROM (299dkk/42€)</MenuItem>
                <MenuItem value={"CoinDB Pro (499kr/70€)"}>CoinDB Pro - www download (499dkk/70€)</MenuItem>
                <MenuItem value={"CoinDB Pro - CDROM (549kr/77€)"}>CoinDB Pro - CDROM (549dkk/77€)</MenuItem>
                <MenuItem value={"ImageDB Std (249kr/35€)"}>ImageDB Std (Film, Musik, Bøger...) - www download (249dkk/35€)</MenuItem>
                <MenuItem value={"ImageDB Std - CDROM (299kr/42€)"}>ImageDB Std (Film, Musik, Bøger...) - CDROM (299dkk/42€)</MenuItem>
                <MenuItem value={"ImageDB Pro (499kr/70€)"}>ImageDB Pro (Film, Musik, Bøger...) - www download (499dkk/70€)</MenuItem>
                <MenuItem value={"ImageDB Pro - CDROM (549kr/77€)"}>ImageDB Pro (Film, Musik, Bøger...) - CDROM (549dkk/77€)</MenuItem>
                <MenuItem value={"TrackSport (149kr/20€)"}>TrackSport (Løb, cykling,...) - www download (149dkk/20€)</MenuItem>
                <MenuItem value={"TrackSport - CDROM (199kr/27€)"}>TrackSport (Løb, cykling,...) - CDROM (199dkk/27€)</MenuItem>
                <MenuItem value={"InventoryDB (kontakt PetriSoft for priser)"}>InventoryDB - asset management system (kontakt PetriSoft for priser)</MenuItem>
                <MenuItem value={"Andet..."}>Andet...</MenuItem>
                {/* <MenuItem>Indbo Pro - www download (499kr/70€)</MenuItem>
                <MenuItem>Indbo Pro - CDROM (549kr/77€)</MenuItem> */}

              </Select>
              <FormHelperText>Vælg program/emne</FormHelperText>
            </FormControl>

            <TextField
              id="outlined-basic" name="user_name"
              onChange={event => setUserName(event.target.value)}
              label="Navn"
              required
              variant="outlined"
              size={"small"}
            />

            <TextField
              id="outlined-basic" name="adresss"
              onChange={event => setAddress(event.target.value)}
              label="Adresse"
              required
              variant="outlined"
              size={"small"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Home />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              id="outlined-basic" name="zip"
              onChange={event => setZip(event.target.value)}
              label="Postnummer"
              type="number"
              required
              variant="outlined"
              size={"small"}
            />

            <TextField
              id="outlined-basic" name="city"
              onChange={event => setCity(event.target.value)}
              label="By"
              required
              variant="outlined"
              size={"small"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationCity />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              id="outlined-basic" name="phone"
              onChange={event => setPhone(event.target.value)}
              label="Telefon"
              required
              type="number"
              variant="outlined"
              size={"small"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              id="outlined-basic"
              name="mail"
              required
              onChange={handleEmailChange}
              label="E-mail"
              variant="outlined"
              size={"small"}
              error={emailError}
              helperText="Indtask en valid email addresse"
              InputProps={{
                placeholder: "f.eks. xxx@gmail.com",
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              id="outlined-basic" name="message"
              onChange={event => setMessage(event.target.value)}
              label="Besked"
              variant="outlined"
              size={"small"}
              multiline
              rows={4}
              maxRows={10}
              InputProps={{
                type: "email",
                startAdornment: (
                  <InputAdornment position="start">
                    <Comment />
                  </InputAdornment>
                ),
              }}
            />

            <br />

            <Button
              variant="contained"
              disabled={disableSend}
              onClick={handleSubmit}
              sx={{ width: 100 }}>Send</Button>
          </Stack>

        </form>

        <br /><br />

        <h3>Hvorfor registrere et PetriSoft program ?</h3>
        Fordelen ved at registrere og købe et PetriSoft program er, at alle
        funktioner bagefter vil virke uden de begrænsninger, der er i gratis versionen.
        Antallet af poster du kan gemme, vil være ubegrænset. Antallet af grupper
        mm vil være afhænging af om man vælger <b>Std</b> eller
        <b>Pro</b> - se mere om forskelle under <a href="https://www.petrisoft.dk/products">
          Produkter.</a> Desuden kan du få ubegræset gratis email support/hjælp.
        <br />
        <br />

        <h3>Sådan registreres et PetriSoft program</h3>
        Man registrerer alle programmerne via en menu i programmet:
        <i>Rediger, Registrer</i> program. Man skal indtaste et <i>bruger id</i> samt et
        <i>licens</i> nummer. <i>Bruger id</i> (et 10 cifret tal) og <i>Licens</i> er begge koder,
        man får tilsendt via email fra PetriSoft - efter betaling af en licens.<br /><br />

        <b>Anvend formen øverst på denne side for at bestille en licens (kræver efterfølgende
          betaling).</b><br /><br />

        Alternativt er du velkommen til at sende en alm. mail med navn, adresse, mail samt ønskede
        program og typen af programmet privat (Std) eller firma (Pro). Mail til <a
          href="mailto:petrisoft@gmail.com?Subject=Bestilling-af-PetriSoft-Licens">
          petrisoft@petrisoft.dk</a>
        <p>

          <br />
          <b>Læs mere om priser samt hvordan du betaler for dit program <a href="https://www.petrisoft.dk/prices">
            her.</a></b></p>

      </section>
    </div >
  );

}

export default Order;
