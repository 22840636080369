import * as React from 'react';
import ErrorBoundary from '../ErrorBoundary';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { appInsights } from 'src/AppInside';
import { useEffect } from 'react';

const Download: React.FC = () => {

    useEffect(() => {
        appInsights.trackPageView({
            name: 'Petrisoft Download page', uri: "https://www.petrisoft.dk/download",
            isLoggedIn: true, properties: { "action": "useeffect: componentDidMount" }
        });
    }, [])

    function onClickDownLoadIndboCore() {
        appInsights.trackEvent({ name: 'Download Indbo Core', properties: { 'version': "5.0.0" } });
    }

    function onClickDownLoadTrackSport() {
        appInsights.trackEvent({ name: 'Download TrackSport', properties: { 'version': "3.0.2" } });
    }

    function onClickDownLoadIndbo() {
        appInsights.trackEvent({ name: 'Download Indbo', properties: { 'version': "4.6.0" } });
    }

    function onClickDownLoadCoinDB() {
        appInsights.trackEvent({ name: 'Download CoinDB', properties: { 'version': "3.1.0" } });
    }

    function onClickDownLoadInventoryDB() {
        appInsights.trackEvent({ name: 'Download InventoryDB', properties: { 'version': "2.2.0" } });
    }

    function onClickDownLoadImageDB() {
        appInsights.trackEvent({ name: 'Download ImageDB', properties: { 'version': "4.4.0" } });
    }

    return (
        <ErrorBoundary>
            <div>
                <Helmet>
                    <title>PetriSoft Download programmer</title>
                    <meta name="description" content="Download et PetriSoft program" />
                    <meta name="Keywords"
                        content="PetriSoft, download, registrering, Indbo, Inventar, InventoryDB, TrackSport, løb, resultat, Møntsamling, CoinDB, ImageDB, gratis demo" />
                    <meta name="author" content="Kurt Petri Petersen, PetriSoft" />
                </Helmet>

                <h1>Download</h1>

                <section>
                    <p>
                        Hent og prøv gratis demo versioner af&nbsp;
                        <b>TrackSport</b> (registrering og analyse af løb, cykling...),&nbsp;
                        <b>Indbo</b> (indbo og inventar),&nbsp;
                        <b>CoinDB</b> (Registrering af Mønt- seddel- og frimærkesamling),&nbsp;
                        <b>ImageDB</b> (Registrering af div. samlinger: Musik, film, kunst, bøger mm),&nbsp;
                        <b>InventoryDB</b> (Inventar registrering m. stregkoder).
                    </p>
                    <p>
                        Gem op til 25 poster uden registrering er nødvendig. Efter download installeres
                        programmerne ved at “køre”&nbsp; “exe” filen - se evt. videoen herunder.
                    </p>
                    <h2>
                        Installation og kom godt igang med Indbo Core 5.x.x
                    </h2>
                    <p>
                        Efter du har hentet Indbo 5.x.x placer Indbo.exe på dit skrivebord. Du vil fremover kunne starte programmet ved at dobbelt klikke på Indbo ikonet.
                        Indbo er udvikklet i C# og er et wpf .Net Core program.
                    </p>
                    <p>
                        <a href="https://www.screencast.com/t/hOqRXTcQlj" target="_blank" rel="noreferrer" >Se video om hvordan du henter, installerer og
                            kommer godt igang med at bruge Indbo version 5.x.x (Windows10/11).</a>
                    </p>

                    <h2>
                        Installation og kom godt igang med TrackSport
                    </h2>
                    <p>
                        Efter du har hentet TrackSport placer TrackSport.exe på dit skrivebord. Du vil fremover kunne starte programmet ved at dobbelt klikke på TrackSport ikonet.
                        TrackSport er udviklet i C# og er et wpf .Net Core program.
                    </p>

                    <p>
                        <a href="https://www.screencast.com/t/1tT5TjWOz" target="_blank" rel="noreferrer" >Se video om hvordan du henter, installerer og
                            kommer godt igang med at bruge TrackSport (Windows10). NB: trin med udpakning af program (unzip) ikke nødvendigt længere...</a>
                    </p>
                    <h2>
                        Installation af CoinDB, ImageDB og InventoryDB (samt Indbo 4.6.0)
                    </h2>

                    Efter du har hentet programmet du ønsker, udpak zip filen (Unzip) and kør Setup.exe.
                    <br />
                    <br />

                    <h2>
                        Hent Programmer
                    </h2>

                    {/* <p>
                        <a href="https://storageaccountpetrisoft.blob.core.windows.net/download/Indbo.exe" onClick={onClickDownLoadIndboCore} download="Indbo.exe">
                            Download Indbo.exe - version 5.1.0 (Azure: https://storageaccountpetrisoft.blob.core.windows.net/download/Indbo.exe)
                        </a>
                    </p> */}
                    <p>
                        <a href="https://www.petrisoft.dk/Download/Indbo.exe" onClick={onClickDownLoadIndboCore} download="Indbo.exe">
                            Download <b>Indbo</b>.exe - version 5.3.0 (Indbo registrering/Windows10/.Net core version/helt nyt program)
                        </a>
                    </p>

                    <p>
                        <a href="https://www.petrisoft.dk/Download/TrackSport.exe" onClick={onClickDownLoadTrackSport} download="TrackSport.exe">
                            Download <b>TrackSport</b>.exe - version 3.0.2 (Registrering af sports aktiviter: løb, cykling, mtb, svømning, vandring mm)
                        </a>
                    </p>
                    <p>
                        <a href="https://www.petrisoft.dk/Download/CoinDB.zip" onClick={onClickDownLoadCoinDB} download="CoinDB.zip">
                            Download <b>CoinDB</b>.zip - version 3.1.0 (Mønt- frimærke- og seddelsamling)
                        </a>
                    </p>
                    <p>
                        <a href="https://www.petrisoft.dk/Download/InventoryDB.zip" onClick={onClickDownLoadInventoryDB} download="InventoryDB.zip">
                            Download <b>InventoryDB</b>.zip - version 2.2.0 (Inventarløsning/Asset Management SW)
                        </a>
                    </p>
                    <p>
                        <a href="https://www.petrisoft.dk/Download/ImageDB.zip" onClick={onClickDownLoadImageDB} download="ImageDB.zip"  >
                            Download <b>ImageDB</b>.zip - version 4.4.0 (Registrering af dine samlinger: Musik, film,
                            kunst, bøger)
                        </a>
                    </p>
                    <h3>Tidligere versioner</h3>
                    <p>
                        <a href="https://www.petrisoft.dk/Download/Indbo.zip" onClick={onClickDownLoadIndbo} download="Indbo.zip">
                            Download <b>Indbo</b>.zip - version 4.6.0 (Indbo registrering - Windows 7/C++/det gamle program)
                        </a>
                    </p>

                    <hr />

                    <article>
                        <section>
                            <h2>Priser</h2>
                            <p>
                                Se alle priser på PetriSofts programmer på siden: <Link to="/prices">Priser</Link>
                            </p>
                        </section>
                        <section>
                            <h2>Bestil et program</h2>
                            Bestil et PetriSoft program her: <Link to="/order">Køb app</Link>
                        </section>
                    </article>

                    <br />
                    <hr />
                    {/* <p>
                        <a rel="noreferrer" href="https://programmer.downloadcentral.dk/"
                            title="Download programmer fra programmer.downloadcentral.dk uden beregning!"
                            target="_blank">Download flere programmer på downloadcentral
                        </a>
                    </p> */}
                </section>
            </div>
        </ErrorBoundary>

    );
};

export default Download;
