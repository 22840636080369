import * as React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import petrisoftLogo from '../../images/petrisoftLogo50x67.gif';

import './Navigation.css';

class Navigation extends React.Component<any, any> {

  // uses React Bootstrap NavBar: https://react-bootstrap.github.io/components/navbar/
  // and React Router v4: https://blog.pusher.com/getting-started-with-react-router-v4/
  // To have a mobile friendly Navbar, Add a Navbar.
  // Toggle to your Header and wrap your Navs in a Navbar.Collapse component
  public render() {
    return (
      <>
        {/* Use the expand prop to allow for collapsing the Navbar at lg (large) breakpoint. */}
        <Navbar bg="primary" variant="dark" expand="lg">
          <Navbar.Brand href="/">
            < img width="30" height="30" className="logo-img" src={petrisoftLogo} alt="PetriSoft" />
            {' PetriSoft'}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {/* <Nav.Link to="/news">Nyheder</Nav.Link > */}
              <Nav.Link href="/news">Nyheder</Nav.Link >
              <NavDropdown title="Produkter" id="basic-nav-dropdown">
                <NavDropdown.Item href="/products" >Alle produkter</NavDropdown.Item >
                <NavDropdown.Divider />
                <NavDropdown.Item href="/coindb">CoinDB</NavDropdown.Item >
                <NavDropdown.Item href="/imagedb">ImageDB</NavDropdown.Item >
                <NavDropdown.Item href="/indbo">Indbo</NavDropdown.Item >
                <NavDropdown.Item href="/inventorydb">InventoryDB</NavDropdown.Item >
                <NavDropdown.Item href="/tracksport">Tracksport</NavDropdown.Item >
                <NavDropdown.Divider />
                <NavDropdown.Item href="/articles">Artikler</NavDropdown.Item >
              </NavDropdown>
              <Nav.Link href="/prices">Priser</Nav.Link>
              <Nav.Link href="/order">Bestil App</Nav.Link>
              <Nav.Link href="/customer">Kunder</Nav.Link>
              <Nav.Link href="/contact">Kontakt info</Nav.Link>
              <Nav.Link href="/download">Download</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link href="/about">Om</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <br />
      </>
    );
  }
}

export default Navigation;
