import * as React from 'react';

import './Customer.css';

class Customer extends React.Component<any, any>  {

    public render() {

        return (
            <div>
                <header>
                    <h1>Kunderne skriver:</h1>
                </header>
                <section>
                    <p>
                        Super sælger alt efter bogen, kan kun varmt anbefales at handle med kun+++++++++++herfra
                    </p>

                    <p>
                        Bra program. Rask levering. Anbefales CoinDB programmet til mynt- og seddelsamlere

                    </p>
                    <p>
                        Fin handel - de bedste anbefalinger.
                    </p>

                    <p>
                        Perfekt, supert program! - køber af CoinDB
                    </p>

                    <p>
                        Topp selger og rask levering. God komunikasjon / info. Anbefales ++++
                    </p>

                    <p>
                        Super dejlig og perfekt handel. Undskylder den sene karakter, CoinDB skulle bruges som gave (manden blev ovenud glad for den) så det var en hemmelighed :-) Anbefales til alle !!
                    </p>

                    <p>
                        Mange++++++++++++++++++++++herfra.
                    </p>
                    <p>
                        Tak for en hurtig handel med et superfint produkt. Mine bedste anbefalinger.
                    </p>

                    <p>
                        Rigtigt godt program - lige hvad jeg manglede. Kan stærkt anbefales. ++++++
                    </p>

                    <p>
                        Perfekt handel og meget hjælpsom sælger.. kan kun anbefales at handle her. tak for handlen :-)
                    </p>

                    <p>
                        Seriøst, greit, raskt og hyggelig. Bare positivt.

                    </p>

                    <p>
                        Bra selger og vare som forventet. God
                        kommunikasjon. Anbefales på det sterkeste. Mange
                        ++++++++++
                    </p>

                    <p>
                        God service, rask forsendelse og gode
                        produkter - kort sagt; kan absolutt anbefales!
                    </p>

                    <p>
                        Kan kun varmt anbefale at handle.
                        Alt i orden, behagelige mails under handlen - alt
                        perfekt - mange +++++++++ her fra.
                    </p>

                    <p>
                        Super handel kun +++++++++++++++ herfra , Tak.
                    </p>

                    <p>
                        Fin vare. Fin Handel. Fin service. Meget fine
                        anbefalinger gives. ++++++
                    </p>

                    <p>
                        TOPP HANDEL BARE ++++++++++++++++++FRA MEG
                    </p>

                    <p>
                        PERFEKT GENNEMFØRT HANDEL OG ET BRUGERVENLIGT
                        PROGRAM. PRØV DET !!!
                    </p>

                    <p>
                        DK/Indbo: Super sælger :D Kan varmt anbefales og programmet er rigtig super til
                        registeringen, super smart :D Tusind tak for handlen +++++++++++
                    </p>
                    <p>
                        Meget fin handel - perfekt sælger. De bedste anbefalinger og bare +++++++++++++++++++
                    </p>
                    <p>
                        DK/CoinDB: Flot program som man kan arbejde med og få utroligt meget ud af. De bedste anbefalinger herfra. ++++++++++++++++++++++
                    </p>
                    <p>
                        Norge: Samlerinfo anbefaler PetriSoft's CoinDB, og gir terningkast 6 for kundeservice!
                    </p>
                    <p>
                        Norge/CoinDB: Mange takk ! Og takk for et <b>kjempefint</b> program. Fungerer fint til <b>sedler!</b> :-) :-)
                    </p>
                    <p>
                        Norge: Rask handel.meget seriøs og hyggelig selger..fin program. ANBEFALLES. Bare plusser fra meg !!
                    </p>

                    <p>
                        Norge: Topp handel! Denne selger kan <b>anbefales</b> på det sterkeste. A++++

                    </p>

                    <p>
                        Norge/CoinDB: Meget bra produkt og en rask og <b>topp</b> <b>handel</b>

                    </p>

                    <p>
                        DK: Programmet er lige det som jeg altid har manglet! Det kan anbefales at læse "kom godt igang" først.
                        Meget <b>profesionel</b> support. <b>CoinDB</b> et must for møntsamlere.
                    </p>

                    <p>
                        DK: Jeg ledte efter et <b>indbo</b> program...,  Så da jeg fandt dit
                        program med de muligheder, det indeholder, ja det var lige det.
                        Det er det <b>bedste</b>, jeg har set til dato.
                    </p>

                    <p>
                        DK: Jeg set/prøvet dit program, det er <b>gennemtænkt</b> og <b>godt.</b>
                    </p>

                    <p>
                        DK: Programmet, er endog <b>meget brugervenligt</b>

                    </p>

                    <p>
                        DK: Tak for <b>god support! </b>Tak for <b>hurtig</b> ekspedition..
                    </p>
                    <p>
                        DK: Det er utroligt dejligt at erfare, at du er så <b>opmærksom</b> og <b>engageret</b> i dine kunder
                    </p>

                </section>

            </div>
        );
    }

}

export default Customer;
