import * as React from 'react';
import './Prices.css';
import { DataGrid, GridColDef, GridEventListener, GridRowSpacingParams, } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { appInsights } from 'src/AppInside';
import Alert from '@mui/material/Alert';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Prices() {

  const [message, setMessage] = React.useState('');

  //const history = useHistory();
  const navigate = useNavigate();

  useEffect(() => {
    appInsights.trackPageView({
      name: 'Petrisoft Prices page', uri: "https://www.petrisoft.dk/prices",
      isLoggedIn: true, properties: { "action": "componentDidMount" }
    });
  }, [])


  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'program', headerName: 'App', width: 130 },
    { field: 'version', headerName: 'Version', width: 130 },
    { field: 'price', headerName: 'Pris (dkk)', width: 80 },
    { field: 'priceEur', headerName: 'Pris (eur)', width: 80 },
    { field: 'os', headerName: 'Operativ system', width: 150 },
    { field: 'description', headerName: 'Beskrivelse', width: 600, },
    { field: 'linkto', headerName: 'Linkto', width: 200, hide: true },
  ];

  const rows = [
    {
      id: 1, program: 'Indbo', version: "5.0.x - Std", price: 249, priceEur: 35,
      description: "Registrering af indbo og inventar (Windows core)", os: "Windows,7/8/10/11", linkto: "/products/indbo"
    },
    {
      id: 2, program: 'Indbo', version: "4.6.0 - Std", price: 249, priceEur: 35,
      description: "Registrering af indbo og inventar (gamle version)", os: "Windows7/8", linkto: "/products/indbo"
    },
    {
      id: 3, program: 'Indbo', version: "4.6.0 - Pro", price: 999, priceEur: 140,
      description: "Registrering af indbo og inventar (gamle version)", os: "Windows7/8", linkto: "/products/indbo"
    },
    {
      id: 4, program: 'CoinDB', version: "3.1.x - Std", price: 249, priceEur: 35,
      description: "Registrering af mønt- og seddelsamling", os: "Windows7/8/10", linkto: "/products/coindb"
    },
    {
      id: 5, program: 'CoinDB', version: "3.1.x - Pro", price: 499, priceEur: 70,
      description: "Registrering af mønt- og seddelsamling", os: "Windows7/8/10", linkto: "/products/coindb"
    },
    {
      id: 6, program: 'TrackSport', version: "3.0.x - Std", price: 149, priceEur: 20,
      description: "Registrering af løbsresultater: løb, cykling, tri, mtb, mm", os: "Windows7/8/10/11", linkto: "/products/tracksport"
    },
    {
      id: 7, program: 'ImageDB', version: "2.2.x std", price: 249, priceEur: 35,
      description: "Registrering af film-, musik-, bog-, kunstsamling mm", os: "Windows7/8/10", linkto: "/products/imagedb"
    },
    {
      id: 8, program: 'InventoryDB', version: "2.2.0 - Pro", price: "", os: "Windows7/8/10",
      description: "Registrering af indbo og inventar med stregkoder, kontakt Petrisoft for pris", linkto: "/products/inventorydb"
    },
    {
      id: 9, program: 'Forsendelse/CD', version: "", price: "50", priceEur: 8,
      description: "Forsendelse af cd-rom med program",
    },
    {
      id: 11, program: 'Fri opdatering', version: "Std", price: 100, priceEur: 15,
      description: "Tilkøb ret til opdatering af Std licens. Prisen er et årligt gebyr, du kan vælge betale, hvis du løbende ønsker at installere evt. opdateringer af et Std program du har købt.", os: "Windows7/8/10/11"
    },
    {
      id: 12, program: 'Fri opdatering', version: "Pro", price: 200, priceEur: 30,
      description: "Tilkøb ret til opdatering af Std licens. Prisen er et årligt gebyr, du kan vælge betale, hvis du løbende ønsker at installere evt. opdateringer af et Pro program du har købt.", os: "Windows7/8/10/11"
    },
    {
      id: 13, program: 'Support', version: "", price: 499, priceEur: 70,
      description: "Tilkøb online support og/eller kom godt igang, 30 min", os: "Windows7/8/10/11"
    },
    {
      id: 14, program: 'Foredrag', version: "", price: 999, priceEur: 140,
      description: "Foredrag f.eks om CoindDB i møntsamler forening eller lignende (dertil evt. transport)", os: ""
    },
  ];

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    setMessage(`Program "${params.row.program}" valgt`);
    if (params.row.linkto !== "")
      navigate(params.row.linkto);
  };

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: 5,
      bottom: 5,
    };
  }, []);

  return (
    <div>
      <section>
        <article>
          <header>
            <h1>Priser, bestilling og betaling</h1>
          </header>
          <section>
            Alle priser er inklusiv moms samt et års gratis email support.
            <p>
              Pro licenser er typisk for professionel brug og giver yderligere mulighed for at tilkøbe support
              - hvis du har problemer, du ikke selv kan løse.
            </p>
            <p>
              Læg mærke til det er  muligt at tilkøbe ret til løbende opdateringer af et program - mod årligt gebyr.
            </p>

            <div style={{ height: '600px', width: '100%' }}>
              <Tooltip title="Klik på linie for at gå til beskrivelse af program" placement="bottom" >

                <DataGrid
                  rows={rows}
                  columns={columns}
                  // pageSize={13}
                  // rowsPerPageOptions={[13]}
                  // checkboxSelection={false}
                  getRowHeight={() => 'auto'}
                  getRowSpacing={getRowSpacing}
                  // sx={{
                  //   [`& .${gridClasses.row}`]: {
                  //     bgcolor: (theme) =>
                  //       theme.palette.mode === 'light' ? grey[50] : grey[900],
                  //   },
                  // }}
                  onRowClick={handleRowClick}

                />
              </Tooltip>
            </div>

            {message && <Alert severity="info">{message}</Alert>}

            <br></br>

            <h2>Betaling</h2>

            <h3>Betaling via bankoverførsel</h3>
            Betaling af PetriSof's programmmer kan foretages på konto: <b>5479-6155739 </b>(NyKredit
            Bank, Kalvebodbrygge 1-3, 1780 København, Danmark, International Bank Account Number
            (IBAN code): <b>DK0254790006155739</b>, BIC Code (Swift code) <b>NYKBDKKK</b>).
            <p>
              Husk at angive program navn, evt. firma, navn, adresse og telefon nummer som betaler/afsender.
            </p>

            <p>
              Når beløbet er registreret hos PetriSoft får du tilsendt (normalt via mail)
              en gyldig Licens som skal indtastes i programmet.
            </p>

            <h3>Betaling via Mobil</h3>
            Du kan betale licenser mm via en <strong>MobilePay</strong> (Danske bank). Send beløbet til mobil
            nummer: 0045 25155069
            <p>
              Du skal dog også sende en email eller sms med dine navn og adresse oplysninger samt licens du har
              købt. Send mail her:&nbsp;
              <a href="mailto: kurt@petrisoft.dk">petrisoft@gmail.com</a>
            </p>


            <h3>Betaling via Kreditkort/PayPal</h3>
            <p>
              Betaling via kreditkort er mulig via PayPal. Du skal indbetale til konto:&nbsp;
              <a href="mailto: kurt@petrisoft.dk">kurt@petrisoft.dk</a>. Du skal lægge 5% oven
              i licens prisen, hvis du ønsker denne betalings form p.gr.af gebyrer mm.
            </p>


            <p>
              Du kan som registreret bruger ofte opnå en stor rabbat, hvis du senere vil købe en licens
              opdatering. Ved samtidig køb af flere programmer/licenser gives 10% rabat.
            </p>
            <p>&nbsp;</p>

          </section>

        </article>


        <article>

          <h2>Rabbatter, opdatering, levering, tilpasning</h2>
          <p>
            Som registreret bruger kan du normalt opnå en god rabbat, hvis du senere ønsker opgradere til en nyere version af
            programmet. kontakt os for at høre nærmere...
          </p>

          <h3>Opdateringer</h3>
          <p>
            Program opdateringer (nye releases af programmerne) finder løbende sted. Der er
            ingen fast frekvens – men det sker typisk, når der er lavet en del forbedringer.
            Du skal selv følge med på <a href="https://www.petrisoft.dk/">https://www.petrisoft.dk/</a> om der er nye
            versioner. Husk altid at læse release notes (evt. her på hjemmesiden) inden du opdaterer programmet
            – samt at <b>lave backup</b> af dine gamle data. PetriSoft sender ofte en nyhedsmail
            ved større program opdateringer direkte til vores kunder, der tidligere har købt
            et program.
          </p>


          <h3>Levering</h3>
          <p>
            Licenser kan leveres pr. e-mail eller almindelig post afhængig af dit ønske (se
            Priser). Email er det billigste, da det sparer dig for alle adm. gebyr samt forsendelsesomkostninger
            og sikrer samtidig hurtigere levering. Du er også velkommen til at hente din
            licens/cd direkte hos PetriSoft.
          </p>

          <h3>Tilpasning og kundespecifik udvikling</h3>
          <p>
            Petrisoft laver meget gerne tilpasninger af programmerne efter kundens ønsker.
            Hvis kunden ønsker funktioner, som alle andre også kan have glæde af udføres arbejdet
            ofte med lavere omkostninger for kunden. Er det mere specifikke ønsker laves
            udviklingen mod en lidt højere timebetaling. Send en mail eller ring endelig hvis
            du ønsker et godt tilbud.
          </p>
          <p>
            Vi laver også gerne helt nye programmer på konsulentbasis efter kundens krav. Vi
            udvikler både i C++/Qt samt i C#/.Net. Du er altid velkommen til få et tilbud på
            en en opgave.
          </p>

          <h2>Licenser, garanti og support</h2>
          <ul>
            <li>Du henter selv programmet på vores <a href="https://www.petrisoft.dk/download">download
              side</a> eller bestiller en CD. </li>

            <li>Spørgsmål/support via e-mail. Bestillinger ekspederes normalt samme dag betaling er modtaget. </li>

            <li>Betalte licenser giver ret til gratis email support i et år fra
              købsdato. Pro licenser giver endvidere mulighed for op til 1/2 times telefon support. </li>

            <li>Manual/Hjælp: online hjælp i html format (Word dokument kan rekvireres).</li>
          </ul>

          <h4>Fortrydelsesretelsesret</h4>
          <p>
            PetriSoft’s programmer udgives som shareware (alle funtioner kan afprøves dog med
            visse begrænsninger), dvs. programmerne kan downloades og afprøves gratis, før du
            køber en licens. Du opfordres hermed til at benytte dig af denne mulighed
            for at sikre dig, at programmet fungerer på din PC og i øvrigt lever op til dine
            forventninger, inden du bestiller en licens :-)
          </p>

          <h4>Garanti</h4>
          <p>
            PetriSoft kan ikke garantere, at programmet er uden fejl og at det fungerer korrekt
            i enhver hardware- og softwarekonfiguration, eller at det i øvrigt lever op til
            bestemte forventninger. PetriSoft kan ikke påtage sig at dække evt. tab eller udgifter,
            som følge af fejl i programmet, operativsystem eller hvis brugeren ikke glemmer
            at lave sikkerhedskopi, overskriver sine data mm. Dvs du har selv det fulde ansvar
            for dine programmer og data.
          </p>

          <p>
            Der er normalt mulighed for support på betalte licenser i op til et år fra købsdato
            - helst via e-mail.
          </p>

          <h4>Prøv programmerne inden du køber</h4>
          Du bør altid afprøve programmet, før du køber det. Hent det på vores download side.<br />
          Du bør altid lave/have sikkerhedskopier af dine egne data for at minimere eventuelle
          tab af data.<br />
          Du bør være opmærksom på, at ændringer i hardware- eller softwarekonfiguration på
          din computer kan betyde, at et program ikke længere fungerer korrekt.

        </article>

      </section>

    </div >
  );
}
