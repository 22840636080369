import * as React from 'react';
import trackSportIcon from './../../images/TrackSport/TrackSportIco.jpg';
import trackSportMain from './../../images/TrackSport/TrackSportMainDk.png';
import trackSportDialog from './../../images/TrackSport/TrackSportVisAktivitetDk.png';
import trackSportHastighed from './../../images/TrackSport/TrackSportHastighedDK.png';
import { Link } from 'react-router-dom';
import { appInsights } from 'src/AppInside';
import './Tracksport.css';
import Helmet from 'react-helmet';

class Tracksport extends React.Component<any, any>  {

  public UNSAFE_componentWillMount() {
    appInsights.trackPageView({name:'Petrisoft TrackSport page', uri:"https://www.petrisoft.dk/tracksport", 
    isLoggedIn:true, properties: { "action": "componentDidMount" }});
  }

  public render() {
    return (
      <div>
        <Helmet>
          <title>TrackSport: registrering af løb, cykling, svømning og andre sports resultater</title>
          <meta name="description" content="TrackSport: registrering og analyse af løb, cykling, svømning og andre sportsresultater" />
          <meta name="Keywords"
            content="TrackSport, træningsdagbog, resultat database, resultat register, aktivitet, registrering, sportsresultater, løb, vandring, cykling, svømning, sportsaktiviteter" />
          <meta name="author" content="Kurt Petri Petersen, PetriSoft" />
        </Helmet>

        <h1>Tracksport</h1>

        <h4>
          <img className="img-app-icon" src={trackSportIcon} alt="TrackSport" />&nbsp;
          Registrering af løb, cykling, mountainbike (MTB), svømning, vandring mm.
        </h4>

        <p>
          <br />
          <b>TrackSport</b> er et Windows/.Net Core program udviklet i C# og WPF, 
          hvor du kan registrere, analysere og vedligeholde dine
          sportsaktiviteter som : løb, cykling, mountainbike (MTB), svømning, vandring 
          mm. Det er især godt til træningsdagbog og/eller registrering af løbsresultater,
          så du senere kan sammeligne og gennemsøge dine forskellige opnåede resultater: f.eks. tempo uge for uge,
          måned for måned osv.
        </p>

        <p>
          Herunder ses TrackSport, hvor en bruger har regisreret diverse konkurrencer i løb:

        <figure>
            <img className="img-tracksport-1" src={trackSportMain} alt="Tracksport oversigtsvinduet" />
            <figcaption>TrackSport: Aktivitetsliste, sortering muligt på alle kolonner samt søgefelt</figcaption>
          </figure>

        </p>

        <p>
          Herunder ses Dialog til indtasningning af en aktivitet i TrackSport, hvor en bruger har regisreret en konkurrencer i løb:

        <figure>
            <img className="img-tracksport-1" src={trackSportDialog} alt="Tracksport aktivitets formular" />
            <figcaption>TrackSport: Dialog til visning og indtastning af aktivitet med links osv</figcaption>
          </figure>

        </p>

        <p>
          Herunder ses analyse af hastighed i løb udvalgt via en søgning i listen på "Marathon" i TrackSport, 
          hvor en bruger har regisreret 11 marathon konkurrencer i løb:

        <figure>
            <img className="img-tracksport-1" src={trackSportHastighed} alt="Tracksport analyse af hastigheder" />
            <figcaption>TrackSport: Analyse og visning af hastighed over tid i udvalgte løb/konkurrencer</figcaption>
          </figure>

        </p>


        {/* Du kan gemme alle dine resultater i en eller flere "databaser" - eller gemme alle resultater i samme - 
          uanset aktivitetstypen.  */}

        <p/>
        <p>
          En demo version af TrackSport kan hentes her:&nbsp;
          <Link to="/download">Download</Link>
        </p>

        <hr />
        <h3>Artikler om løb og træning (TrackSport)</h3>

        <br />
        <br />

        <h4>af Kurt Petri Petersen: 100 dage i træk med løb</h4>
        <h5>samt generelle tanker om det at blive en bedre og stabil løber samt at registrere løbsresultater</h5> 
        <a href="https://www.loeberne.dk/100-dage-i-traek-med-loeb-samt-generelle-tanker-om-det-at-blive-en-bedre-og-stabil-loeber/">100 dage i træk med løb</a>

        {/* <iframe src="http://docs.google.com/gview?url=http://www.petrisoft.dk/Artikler/100-dage-med-loeb.pdf&embedded=true" 
          style={{width:'700px', height:'500px'}} /> */}
        
      </div>
    );
  }
}

export default Tracksport;