import * as React from 'react';
import { appInsights } from 'src/AppInside';
import { useEffect } from 'react';

const Articles: React.FC = () => {

  useEffect(() => {
    appInsights.trackPageView({name:'Petrisoft Articles page', uri:"https://www.petrisoft.dk/acticles", 
    isLoggedIn:true, properties: { "action": "useeffect: componentDidMount" }});
  }, [])
 
  return (
    <div>
      <h1>Artikler</h1>

      <p>På denne side kan du finde diverse artikler og materiale om PetriSoft.</p>

      <hr />
      
      <h3>Artikler om løb og træning (TrackSport)</h3>
      <br/>
      
      <h5>100 dage i træk med løb samt generelle tanker om det at blive en bedre og stabil løber samt at registrere løbsresultater</h5> 
      
      <a href="https://www.loeberne.dk/100-dage-i-traek-med-loeb-samt-generelle-tanker-om-det-at-blive-en-bedre-og-stabil-loeber/">100 dage i træk med løb, af Kurt Petri Petersen</a>

      <hr />

      <h3>Artikler om CoinDB (Mønt- og seddelsamling)</h3>
      <br/>
      <h4>Artiklel om CoinDB i bladet møntsamleren</h4>
      <a href="http://docs.google.com/gview?url=http://www.petrisoft.dk/Artikler/CoinDB-presentation-moentsamleren.pdf&embedded=true">CoinDB i bladet møntsamleren</a>

    </div>
  );
};

export default Articles;


