import * as React from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';

import './Main.css';
import tracksportImage from './../../images/TrackSport/peopleSport.jpg';
import coinDBDialogImage from './../../images/CoinDB/CoinCollection.jpg';
import indboMainImage from './../../images/Indbo/IndboHouse.jpg';
import imageDBMusikDialogImage from './../../images/ImageDB/ImageDBCover.jpg';
import zebraPrinter from 'src/images/InventoryDB/Zebra2844-z.gif';

import newsImage from './../../images/News.jpg';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { appInsights } from 'src/AppInside';

const Main: React.FC = (): JSX.Element => {

  const navigate = useNavigate();

  useEffect(() => {
    showInfo();

    appInsights.trackPageView({
      name: 'Petrisoft home page', uri: "https://www.petrisoft.dk/",
      isLoggedIn: true, properties: { "action": "Main page shown" }
    });
  }, [])


  function showInfo() {
    const options = {
      autoClose: 2000,
      hideProgressBar: true,
      position: toast.POSITION.TOP_RIGHT,
      type: toast.TYPE.INFO,
    };
    toast.info("Gå til Download for at hente demo programmer", options)
  }

  const handleTrackSportClick = () => { navigate("/tracksport") };
  const handleCoinDBClick = () => navigate("/coindb");
  const handleIndboClick = () => { navigate("/indbo") };
  const handleImageDBClick = () => navigate("/imagedb");
  const handleInventoryDBClick = () => navigate("/inventorydb");
  const handleReadNewClick = () => navigate("/news");
  const handleDownloadClick = () => navigate("/download");

  return (
    <div>
      <section>
        <article>
          <header>
            <Typography variant="h4" component="span" gutterBottom>
              PetriSoft - Software til katalogisering- og registrerings opgaver
            </Typography>
          </header>

          <Typography variant="body1" gutterBottom>

            <section>
              PetriSoft laver programmer og App's til mange forskellige opgaver inden for registrering:&nbsp;
              <b>indbo og inventar, film, musik, kunst, bøger, mønt-, frimærke- og seddelsamling samt registreing af løb- og konkurrence resultater</b>.
              Registreringer kan være særdeles nyttige som dokumentation i forbindelse med tyverisikring af indbo og inventar i hus, sommerhus, skole, firma mm.
              <br />
              <br />
              Programmerne kan frit downloades og afprøves - helt uden tidsbegrænsning -
              kun begrænset i antal poster, der kan gemmes.
              <br />
              <br />
              De fleste af PetriSoft's programmer kan fås med dansk, norsk og engelsk brugergrænseflade.
              <br />
              <br />
              PetriSoft tilbyder deuden at lave tilpasninger af programmerne.
              <br />
              <br />
              PetriSoft holder også foredrag: CoinDB til din at registrere sin møntsamling, foredrag om registrering af løbsresulater i din løbklub,
              (Garmin, TrackSport mm), registrering af indbo og inventar i hjemmet, skolen eller firmaet (Indbo/InventoryDB).
              Har du spørgsmål så
              <a className="Mailref" href="mailto:petrisoft@gmail.com?Subject=Forespørgsel%20til%20PetriSoft"> kontakt </a>
              PetriSoft.
            </section>
          </Typography>

          <br />
          <br />
        </article>
      </section>
      {/*     
          xs, extra-small: 0px
          sm, small: 600px
          md, medium: 900px
          lg, large: 1200px
          xl, extra-large: 1536px */}

      <Grid container spacing={2}>

        <Grid item xs={12} sm={6} md={4}>
          <Card variant='elevation' raised>
            <Typography variant="h6" gutterBottom >
              Indbo, inventar regstrering, tyverisikring
            </Typography>

            <CardActionArea onClick={handleIndboClick}>
              <CardMedia
                height={180}
                component="img"
                image={indboMainImage}
                alt="Indbo"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" >
                  Indbo
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Program til registrering af indbo, inventar, egendele.
                </Typography>
              </CardContent>
            </CardActionArea>

            <CardActions>
              <Button size="small" onClick={handleDownloadClick} >Download</Button>
              <Button size="small" onClick={handleIndboClick}>Info om Indbo</Button>
            </CardActions>

          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card variant='elevation' raised>
            <Typography variant="h6" gutterBottom >
              Mønt, seddel- og frimærke register
            </Typography>
            <CardActionArea onClick={handleCoinDBClick}>
              <CardMedia
                height={180}
                component="img"
                image={coinDBDialogImage}
                alt="CoinDB"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="span">
                  CoinDB
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Program til registrering af Mønt-, seddel-, nødseddel-, medalje- og frimærkesamlinger.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" onClick={handleDownloadClick} >Download</Button>
              <Button size="small" onClick={handleCoinDBClick}>Info om CoinDB</Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card variant='elevation' raised >
            <Typography variant="h6" gutterBottom >
              Sports resultat registrering- løb mm
            </Typography>

            <CardActionArea onClick={handleTrackSportClick}>
              <CardMedia
                height={180}
                component="img"
                image={tracksportImage}
                alt="TrackSport"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="span">
                  TrackSport
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Program til registrering af resultater i atletik, løb, cykling, triatlon mm
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" onClick={handleDownloadClick} >Download</Button>
              <Button size="small" onClick={handleTrackSportClick}>Info om TrackSport</Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card variant='elevation' raised>
            <Typography variant="h6" gutterBottom >
              Inventar registrering med stregkoder
            </Typography>
            <CardActionArea onClick={handleInventoryDBClick}>
              <CardMedia
                height={200}
                component="img"
                image={zebraPrinter}
                alt="InventoryDB"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="span">
                  InventoryDB
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Program til registrering af inventar med stregkoder (til skoler, virksomheder)
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" onClick={handleDownloadClick} >Download</Button>
              <Button size="small" onClick={handleInventoryDBClick}>Læs om InventoryDB</Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card variant='elevation' raised>
            <Typography variant="h6" gutterBottom >
              Musik- film- og bogsamlinger
            </Typography>

            <CardActionArea onClick={handleImageDBClick}>
              <CardMedia
                height={230}
                component="img"
                image={imageDBMusikDialogImage}
                alt="ImageDB"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="span">
                  ImageDB
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Program til registrering af musik, film, bøger mm
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" onClick={handleDownloadClick} >Download</Button>
              <Button size="small" onClick={handleImageDBClick}>Info om ImageDB</Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card variant='elevation' raised>
            <Typography variant="h6" gutterBottom >
              Nyheder, info om releases
            </Typography>
            <CardActionArea onClick={handleReadNewClick}>
              <CardMedia
                component="img"
                image={newsImage}
                alt="News"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="span">
                  Nyheder
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Nyheder fra PetriSoft
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" onClick={handleReadNewClick}>Læs Nyheder</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

    </div >
  );
}

export default Main;