import * as React from 'react';

import coinDBDialogVedligehold from "./../../images/CoinDB/CoinDBDialogVedligehold.png";
import coinDbIcon from './../../images/CoinDB/CoinDBIco.png';
import CoinDBMainWinMedDialog from './../../images/CoinDB/CoinDBMainMedDialog.png';
import { appInsights } from 'src/AppInside';
import './CoinDB.css';
import Helmet from 'react-helmet';

class CoinDB extends React.Component<any, any>  {

  public UNSAFE_componentWillMount() {
    appInsights.trackPageView({
      name: 'Petrisoft CoinDB page', uri: "https://www.petrisoft.dk/coindb",
      isLoggedIn: true, properties: { "action": "componentDidMount" }
    });
  }

  public render() {
    return (
      <div>

        <Helmet>
          <title>CoinDBDB: Registrering af mønter, sedler, frimærker, nødsedler mm</title>
          <meta name="description" content="CoinDB, registrering af mønter, møntsamling, sedler, seddelsamling, frimærker og medaljer, frimærkesamling" />
          <meta name="Keywords"
            content="PetriSoft, CoinDB, registrering, møntsamler, frimærkesamler, registrering af mønter, møntsamling, sedler, seddelsamling, frimærker, frimærkesamling, medaljer, tyverisikring" />
          <meta name="author" content="Kurt Petri Petersen, PetriSoft" />
        </Helmet>

        <h1>
          CoinDB
        </h1>

        <h4>
          <img className="img-app-icon" src={coinDbIcon} alt="CoinDB" />&nbsp;
          Registrering af mønter, sedler, frimærker og medaljer
        </h4>

        <p>
          <br />
          <b>CoinDB</b> er det perfekte program for mønt- frimærke- og seddelsamlere.
        </p>

        <p>
          Med CoinDB er det let at organisere og registrere en eller flere mønt- frimærke-
          eller seddelsamlinger. Oprindeligt udviklet til møntsamlere men senere udvidet til sedler og frimærker.
        </p>

        <p>
          En af de mange fordele ved dette program, er de mange stamdata, der
          enten medfølger programmet (gælder især for mønter).
          Det betyder ofte, at du ikke behøver at indtaste alle stamdata selv og let kan lave mango-lister over mønter du mangler.
          Der medfølger en online "kom godt igang vejledning" som du bør følge når du starter...
        </p>

        <p>
          Man kan lave forskellige databaser med hver sin opsætning og således
          definere felter, der passer til formålet. De fleste kan dog sagtens klare sig med opsætningen i de medfølgende demo databaser
          for mønter, sedler, nødsedler samt frimærker, der installeres sammen med programet.
        </p>

        <p>
          I menuen Hjælp, Kom godt i gang, beskrives det, hvordan du
          kan indlæse disse stamdata for både norske- og danske årgangsmønter - såvel som
          nødsedler og frimærker
        </p>

        <p>
          Det er let at søge, lave oversigter og rapporter over sine samlinger eller
          enkelte poster. CoinDB kan også med fordel anvendes som et salgsredskab, idet
          samlingen lettere kan fremvises til potentielle købere – når den først er lagret
          elektronisk. Ligeledes kan man en fin rapport om hver eneste mønt, seddel osv.
        </p>

        <p>
          Hver post (mønt/seddel/frimærke) kan f.eks vises og gemmes, printes og
          mailes som en html side med links, billeder osv. Senest er der tilføjet en meget avanceret HTML skema rapport funktion,
          hvor man kan liste alle eller udvalgte mønter, sedler eller frimærker.
        </p>

        <p>
          Herunder ses dialogen du kan indtaste information om en mønt eller seddel i din samling:
        </p>

        <figure>
          <img className="img-coindb-1" src={coinDBDialogVedligehold} alt="CoinDB" />
          <figcaption>CoinDB dialog til vedligeholdelse af mønt eller seddel</figcaption>
        </figure>

        <br />

        <p>
          Registreringerne kan desuden med stor fordel anvendes, i forbindelse med vurdering af mønt- frimærke- eller
          seddelsamlingens forsikringssum, hvilket også er en fordel at have i tilfælde af tyveri, brand osv.
        </p>

        <p>
          Man kan f.eks. for mønter gemme information om:
          Type, Årstal, Materiale, Antal, Købspris, Vurdering, Forside/Bagside billede, Dato,
          Regent, Beskrivelse, Note, MøntKatalog, Kvalitet, Katalog nummer og Www-link. Tilsvarende felter findes for sedler og frimærker...
        </p>

        <p>
          Yderligere er det muligt at oprette og vedligeholde flere forskellige registre/samlinger.
          F.eks kan man bruge programmet til at gemme de danske årgangsmønter i et register
          og pengesedler i et andet register.
        </p>

        <figure>
          <img className="img-coindb-2" src={CoinDBMainWinMedDialog} alt="CoinDB hovedvinduet" />
          <figcaption>PetriSofts Møntsamling - CoinDB med oversigt over mønter samt dialog til vedligeholdelse</figcaption>
        </figure>

        <hr />

        <h3>Artikler om CoinDB</h3>

        <br />

        <h4>Præsentation af CoinDB i bladet "Møntsamleren"</h4>

        <a href='https://docs.google.com/gview?url=http://www.petrisoft.dk/Artikler/CoinDB-presentation-moentsamleren.pdf&embedded=true'>Læs artikel</a>


      </div>
    );
  }
}

export default CoinDB;