import React from 'react'
// import { Outlet } from 'react-router-dom'

import { Link } from 'react-router-dom';
import coinDbIcon from '../../images/CoinDBIco.png';
import imageDbIcon from '../../images/ImageDbIco.png';
import indboIcon from '../../images/IndboIco.png';
import inventoryDbIcon from '../../images/InventoryDBIco.png';
import tracksportIcon from '../../images/TrackSport.jpg';

export default function ProductList() {

  return (
    <div>
      <article>
        <h2>Oversigt</h2>
        <section>
          PetriSoft udvikler, sælger og supporterer softwareprogrammer (app's) til diverse registreringsopgaver.
          <br />
          <br />
        </section>

        <h3>PetriSoft's app's:</h3>

        <table summary="Produkter" id="ProduktTabel" className="table table-bordered" >
          <thead>
            <tr>
              <th scope="col">Program</th>
              <th scope="col">Beskrivelse</th>
            </tr>
          </thead>

          <tbody>

            <tr>
              <td>
                <Link to="/coindb">CoinDB</Link>
              </td>
              <td>
                <Link to="/coindb">
                  <img className="img-app-icon" src={coinDbIcon} alt="Vis info om CoinDB" width="34" />
                </Link>
                &nbsp; Hold styr på din mønt- frimærke- eller seddelsamling.
              </td>
            </tr>

            <tr>
              <td>
                <Link to="/imagedb">ImageDB</Link>
              </td>
              <td>
                <Link to="/imagedb">
                  <img className="img-app-icon" src={imageDbIcon} alt="Vis info om ImageDB" />
                </Link>
                &nbsp; Lav din egen database til musik, film, bøger, kunst...
              </td>
            </tr>

            <tr>
              <td>
                <Link to="/indbo">Indbo</Link>
              </td>
              <td>
                <Link to="/indbo">
                  <img className="img-app-icon" src={indboIcon} alt="Vis info om Indbo" />
                </Link>
                &nbsp; Indbo- og inventar register.
              </td>
            </tr>

            <tr>
              <td >
                <Link to="/inventorydb">InventoryDB</Link>
              </td>
              <td>
                <Link to="/inventorydb">
                  <img className="img-app-icon" src={inventoryDbIcon} alt="InventoryDB" />
                </Link>
                &nbsp; Asset management system: inventarløsning&nbsp; med stregkode printer og skanner support.
              </td>
            </tr>

            <tr>
              <td >
                <Link to="/tracksport">Tracksport</Link>
              </td>
              <td >
                <Link to="/tracksport">
                  <img className="img-app-icon" src={tracksportIcon} alt="TrackSport" />
                </Link>
                &nbsp; Registrering og sammenligning af sports resultater som løb, cykling, MTB, svømning...
              </td>
            </tr>

          </tbody>
        </table>
      </article>

      <br />
      {/* <Outlet /> */}

      <br />
      <hr />

      <article>
        <section>
          <h2>Priser</h2>
          Se priser på PetriSofts programmer her: <Link to="/prices">Priser</Link>
        </section>

        <br />

        <section>
          <h2>Bestil et program</h2>
          <Link to="/order">Bestil</Link> et PetriSoft program <Link to="/order">her</Link>.
        </section>

        <br />

        <section>
          <h2>Artikler om programmer</h2>
          <Link to="/articles">Artikler</Link>
        </section>

      </article>



    </div>
  );
}
