import * as React from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { appInsights } from 'src/AppInside';
import './Contact.css';


const Contact: React.FC = () => {
  // const theme: Theme = useTheme();

  const position = { lat: 56.0512, lng: 9.9839 };

  React.useEffect(() => {
    appInsights.trackPageView({
      name: 'Petrisoft Contact page', uri: "https://www.petrisoft.dk/contact",
      isLoggedIn: true, properties: { "action": "componentDidMount" }
    });
  });

  const MapArea: React.FC = () => {
    return (
      <Map center={position} zoom={13}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
        <Marker position={position}>
          <Popup>
            <span>PetriSoft <br /> email: petrisoft@gmail.com<br /> mobil: +45 25155059</span>
          </Popup>
        </Marker>
      </Map>
    );
  }

  return (
    <div>

      <header>
        <h2>Kontakt PetriSoft</h2>
      </header>

      <table summary="Produkter" id="AboutPetriSoftTable" className="table table-bordered" title="Om PetriSoft">
        <tbody>
          <tr>
            <td>
              <b>Firma navn:</b>
            </td>
            <td>
              PetriSoft
            </td>
          </tr>
          <tr>
            <td>
              <b>Adresse:</b>
            </td>
            <td>
              Lodbrogsvej 33, 8660 Skanderborg, DK
            </td>
          </tr>
          <tr>
            <td>
              <b>Mobil:</b>
            </td>
            <td>(0045) 25155069 </td>
          </tr>
          <tr>
            <td>
              <b>Email:</b>
            </td>
            <td>
              <a href="mailto:petrisoft@gmail.com?Subject=Forespørgsel%20til%20PetriSoft">petrisoft@gmail.com</a>
            </td>
          </tr>
          <tr>
            <td>
              <b>Web side:</b>
            </td>
            <td>
              <a href="http://www.petrisoft.dk">www.petrisoft.dk</a>
            </td>
          </tr>

          <tr>
            <td >
              <b>Facebook:</b>
              &nbsp;</td>
            <td>
              <a href="https://www.facebook.com/pages/PetriSoft/175887722453656">www.facebook.com/pages/PetriSoft/175887722453656</a>
            </td>

          </tr>
          <tr>
            <td >
              <b>Kontakt person:</b>
            </td>
            <td>
              Kurt Petri Petersen
            </td>
          </tr>
        </tbody>
      </table>

      <MapArea />

    </div >
  );



}

export default Contact;
