import * as React from 'react';
import { Link } from 'react-router-dom';
import indboMain from './../../images/Indbo/Indbo5.0.0Eksempel.png';
import indboItemDialog from './../../images/Indbo/Indbo.5.0.0.Dialog.png';
import indboDbIcon from './../../images/IndboIco.png';
import indboHtmlReport from '../../images/Indbo/Indbo530.Html.Report.png';
import indboHtmlSelectFieldsInReport from '../../images/Indbo/Indbo530.Html.Report.Select.fields.png';
import { appInsights } from 'src/AppInside';
import './Indbo.css';
import Helmet from 'react-helmet';

class Indbo extends React.Component<any, any>  {

  public UNSAFE_componentWillMount() {
    appInsights.trackPageView({
      name: 'Petrisoft Indbo page', uri: "https://www.petrisoft.dk/indbo",
      isLoggedIn: true, properties: { "action": "componentDidMount" }
    });
  }

  public render() {
    return (
      <div>

        <Helmet>
          <title>Indbo: registrering af indbo og inventar, tyverisikring</title>
          <meta name="description" content="Indbo: registrering af indbo og inventar, personlige ejendele, tyverisikring" />
          <meta name="Keywords"
            content="Indbo, database, registrering, indbo, inventar, personlige ejendele, tyverisikring" />
          <meta name="author" content="Kurt Petri Petersen, PetriSoft" />
        </Helmet>

        <h1>Indbo</h1>

        <h4>
          <img className="img-app-icon" src={indboDbIcon} alt={"Indbo"} />&nbsp;
          Registrering af indbo og inventar
        </h4>

        <p>
          <b>Indbo </b>er et Windows program, der kan holde rede på hjemmets, firmaets, foreningens eller skolens indbo og inventar
          - dvs lave et kartotek/register med alle ejendele og dermed give mangfoldige muligheder for at lave rapporter samt søge
          efter poster.
        </p>

        <p>
          Indbo og inventar registeret kan med fordel anvendes i forbindelse med vurdering af
          indbo forsikringssum. I tilfælde af tyveri eller brand kan det give en god dokumentation
          over evt. mistede genstande.
          <br /><br />

          Man kan gemme navn, gruppe, produkt, fabrikant, type, model, antal, pris (anskaffelse),
          vurdering, dato, lokale, leverandør, beskrivelse, serienummer, information, billede, kvittering, web-adresse (link til et produkts manual eller beskrivelse), billede,
          link til dokumentation, manual, 3 bruger definerede links (diverse links om produktet - enten lokalt på pc eller web).
          Kort sagt kan du gemme alle relvante informationer der beskriver vigigt indbo og inventar.
        </p>

        <p>
          Yderligere er det muligt at oprette og vedligeholde flere forskellige registre. F.eks kan man bruge programmet til at
          gemme hjemmets inventar i et register, sommerhusets inventar i et andet... der er ingen begrænsninger.
        </p>

        <br />

        <p>
          Programmet kan med fordel anvendes til registreringsopgaver som: Indbo, inventar,
          egendele, anlægsaktiver, styklister, og meget mere.

          Alternativt bør du anvende <Link to="/imagedb">ImageDB</Link>,
          hvis du specifikt ønsker at registrere din musiksamling, filmsamling, bogsamling, kunstsaming eller lignende.
        </p>

        <p>
          Det anbefales, at man starter med at registrere alle de nye indkøb, der har en vis værdi: radio, tv, hifi, pc'er,
          tablets, mobiler, møbler, smykker osv.
          <br /><br />

          I figuren herunder er vist et skærmbillede af listen med en indbo database. I søgefeltet har brugeren fremsøgt alt "Radio, Tv".


        </p>

        <p>
          <figure>
            <img className="img-indbo-1" src={indboMain} style={{ width: '900px', height: 'auto' }} alt="Indbo 5.3.0 hoved vinduet" />
            <figcaption>PetriSoft's Indbo - hovedvinduet med søgbar liste med indbo data</figcaption>
          </figure>
        </p>

        <p>
          Dobbelt klikker man på en af liniere (eller vælger "Ret..." i værktøjslinien) fremkommer en dialog form,
          hvor man kan indtaste eller ændre sine indbo
          data:
        </p>

        <p>
          <figure>
            <img className="img-indbo-2" src={indboItemDialog} style={{ width: '700px', height: 'auto' }} alt="Indbo redigering form" />
            <figcaption>PetriSoft's Indbo - dialog/form til oprettelse og vedligeholdelse indbo data</figcaption>
          </figure>
        </p>

        <p>
          Indsæt link til kopi af kvittering/faktura - måske du allerede har fået en mail med disse -
          så er det bare at gemme et link i programmet...
          Tag et billede af genstanden eller hent et billede fra en webside og
          lav også et link til evt. bruger manual, produktbeskrivelse osv.
          Normalt kan alle disse informationer downloades fra nettet. Fordelen er du aldrig mere behøver at lede efter din dokumentation,
          når du for brug for den... og det gør de fleste jo jævnligt ? ;).
        </p>

        <p>
          Vil du lave en detaljeret rapport med fremsøgt inventar eller indbo kan vise de fremsøgte poster via "Vis i HTML tabel..." funtionen.
          Du kan nu vælge de felter der skal vises:
        </p>

        <p>
          <img src={indboHtmlSelectFieldsInReport} style={{ width: '300px', height: 'auto' }} alt="Indbo - select fields" />
        </p>

        Herefter vises de fremsøgte elemeneter med de valgte felter:

        <p>
          <img src={indboHtmlReport} style={{ width: '800px', height: 'auto' }} alt="Indbo - show report" />
          <figcaption>PetriSoft's Indbo - Visning af fremsøgt indbo i html tabel form</figcaption>
        </p>


        <br />

        <h3>
          Programmet findes i 2 forskellige versioner:
        </h3>
        <br /><br />

        <p>
          <h4>
            PetriSoft’s <b>Indbo Free:</b>
          </h4>
          er gratis, og man kan afprøve alle funktioner og gemme op til 25 poster i hvert arkiv.
          Anvend gratis programmet lige så længe du vil og køb en licens, hvis du har brug for at gemme flere poster.
        </p>

        <p>
          <h4>
            PetriSoft’s <b>Indbo Std</b>
          </h4>
          Program med en licens og typisk for både private brugere eller mindre foreninger, firmaer, skoler...
          F.eks. til registrering af indbo/inventar i hjemmet, sommerhuset eller i et mindre firma, skole eller lign.
          I nyeste Indbo (version {'>'}= 5.x.x) er der ingen begrænsninger i antallet af poster, inventar grupper, eller lokaler.
        </p>

        <p>
          <a rel="noreferrer" href="https://www.screencast.com/t/hOqRXTcQlj" target="_blank">Se video om hvordan du henter, installerer og
            kommer godt igang med at bruge Indbo version 5.x.x til Windows 10/11.</a>
        </p>

        <p>
          <a href="http://docs.google.com/gview?url=http://www.petrisoft.dk/Artikler/importer-gammel-Indbo-database.pdf&embedded=true">Guide til konvertering fra Indbo version 4.x.x (Windows7) til version 5.x.x (Windows10/11)</a>
        </p>

        {/* <p>
          <h4>
            PetriSoft’s <b>Indbo Pro (udkommer kommer snart):</b>
          </h4>
          Denne version er for brugere, der ønsker nogen form for begrænsninger:
          Ideel til både private samt firmaer, skoler, institutioner og foreninger med et
          stort registrerings behov. Her kan der f.eks. oprettes et ubegrænset antal inventar
          grupper, lokaler samt leverandører mm. I denne version er export i XML format
          endvidere mulig.
        </p> */}

        <br />

        <p>
          Indbo 5.0.0 er udgivet på <b>dansk og engesk</b>.
        </p>

      </div>
    );
  }
}

export default Indbo;