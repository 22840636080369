import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let appInsights: ApplicationInsights;
export { appInsights };

export function initializeApplicationInsights() {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: 'b408aefa-1000-447d-ae49-113a8d0b2641'
      // instrumentationKey: '2b987b88-210d-49d3-a645-7b15e90a022d'
      /* ...Other Configuration Options... */
    }
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
}

