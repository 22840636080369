import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
//import { lazy } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Helmet from 'react-helmet';
import CssBaseline from '@mui/material/CssBaseline';


import About from './components/About';
import CoinDB from './components/CoinDB';
import Contact from './components/Contact';
import Customer from './components/Customer';
import Download from './components/Download';
import ImageDB from './components/ImageDB';
import Indbo from './components/Indbo';
import InventoryDB from './components/InventoryDB';
import Main from './components/Main';
import News from './components/News';
import Order from './components/Order';
import Prices from './components/Prices';
// import Products from './components/Products';
import ProductList from './components/Products/ProductList';
import Articles from './components/Products/Articles';
import Tracksport from './components/Tracksport';
import Footer from './components/Footer';
import Navigation from './components/Navigation';

// const About = lazy(() => import('./components/About'));
//const CoinDB = lazy(() => import('./components/CoinDB'));
// const Contact = lazy(() => import('./components/Contact'));
// const Customer = lazy(() => import('./components/Customer'));
// const Download = lazy(() => import('./components/Download'));
//const ImageDB = lazy(() => import('./components/ImageDB'));
//const Indbo = lazy(() => import('./components/Indbo'));
//const InventoryDB = lazy(() => import('./components/InventoryDB'));
// const Main = lazy(() => import('./components/Main'));
// const News = lazy(() => import('./components/News'));
// const Order = lazy(() => import('./components/Order'));
// const Prices = lazy(() => import('./components/Prices'));
// const Products = lazy(() => import('./components/Products'));
// const ProductList = lazy(() => import('./components/Products/ProductList'));
// const Articles = lazy(() => import('./components/Products/Articles'));
//const Tracksport = lazy(() => import('./components/Tracksport'));
//const Footer = lazy(() => import('./components/Footer'));
//const Navigation = lazy(() => import('./components/Navigation'));


// component is rendered when the URL matches "/products". It also acts as a container for the nested routes related to products.
// The ProductList component is rendered when the URL matches "/products" exactly
// index declares this route as the default child route to render in the parent's Outlet when there is no other child to render. index is the default placeholder content for an empty path.
function App() {
  return (
    <div className="App">

      <title>PetriSoft Hjem</title>
      <Helmet>
        <title>PetriSoft's app's og programmer</title>
        <meta charSet="utf-8" />
        <meta name="description" content="PetriSoft's programmer: registrering af indbo og inventar, tyverisikring, asset management SW, løb, cykling, film, musik, mønter, sedler, bøger" />
        <meta name="Keywords"
          content="PetriSoft, registrering, indbo, inventar, tyverisikring, asset management, løb, løbsregistrering, cykelløb, resultat, database, register, film database, musik database, mønt database, seddel database, bog database, gratis demo" />
        <meta name="author" content="Kurt Petri Petersen, PetriSoft" />
        {/* <meta name="viewport" content="width=device-width, initial-scale=1.0"/> */}
      </Helmet>
      <div className="content">
        <CssBaseline />
        <Navigation />
        <Routes>
          <Route index path="/" element={<Main />} />
          <Route path="/customer" element={<Customer />} />
          <Route path="/download" element={<Download />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/prices" element={<Prices />} />
          <Route path="/order" element={<Order />} />
          <Route path="/news" element={<News />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/coindbArticle" element={<Tracksport />} />

          <Route path="/products" element={<ProductList />} />
          {/* <Route index element={<ProductList />} /> */}
          <Route path="/indbo" element={<Indbo />} />
          <Route path="/coindb" element={<CoinDB />} />
          <Route path="/imagedb" element={<ImageDB />} />
          <Route path="/inventorydb" element={<InventoryDB />} />
          <Route path="/tracksport" element={<Tracksport />} />
          <Route
            path="*"
            element={
              <div style={{ padding: "1rem" }}>
                <h3>Fejl - Siden ikke fundet...</h3>
              </div>
            }
          />
        </Routes>
      </div>
      <Footer />
      <ToastContainer autoClose={5000} />
    </div>
  )
}

export default App;

