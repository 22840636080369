import * as React from 'react';
import { toast } from 'react-toastify';
import './ErrorBoundary.css';

const MISSING_ERROR = 'Error was swallowed during propagation.';

interface IErrorState {
  readonly error: Error | null | undefined;
  readonly errorInfo: string;
}

// see https://codepen.io/gaearon/pen/wqvxGa?editors=0010
 class ErrorBoundary extends React.Component<any, any>  {

   public state: IErrorState = {
    error: undefined,
    errorInfo: ""
  };

  constructor(props: any) {
    super(props);

    this.showError = this.showError.bind(this);
  }

  public showError(error: string) {
    const options = {
      autoClose: 8000,
      hideProgressBar: true,
      position: toast.POSITION.BOTTOM_LEFT,
      type: toast.TYPE.ERROR,
    };
    toast.info(error, options)
  }
 
   public componentDidCatch(error: Error | null, errorInfo: object) {
    this.setState({ error: error || new Error(MISSING_ERROR) });
    // this.setState({ errorInfo: object. });
    this.showError("Fejl på PetriSoft webside, ! gå tilbage med pilen i browser menu'en");
    // You can also log the error to an error reporting service
    this.logErrorToCloud(error);
  }

  public logErrorToCloud = (error: Error | null) => {

    const stackError: string = error != null ? (error.stack !== undefined ? error.stack : "") : "";
    // tslint:disable-next-line:no-console
    console.log(stackError);

    // TODO: send error report to cloud
  };

  public render() {
    if (this.state.error) {
      this.setState({ error: undefined });

      // You can render any custom fallback UI
      // return <h1>Ups - Der er opstod en fejl på PetriSoft's side ! Gå tilbage med pil i browser menu'en</h1>;
    }
    return this.props.children; 
  }
}

export default ErrorBoundary; 